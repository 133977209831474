import api from "@/api/api.js";

class AuthService {
  login(user) {
    return api
      .userLogin({
        email: user.email,
        password: user.password,
      })
      .then(async (response) => {
        if (response.data.token_data && response.data.profile) {
          const user = {
            ...response.data.token_data,
            ...response.data.profile,
          };
          localStorage.setItem("user", JSON.stringify(user));
        }
        const { data: lar_token } = await api.getToken({
          email: user.email,
          password: user.password,
        });
        localStorage.setItem("larToken", JSON.stringify(lar_token));
        return response.data.profile;
      });
  }
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("lar_token");
  }
  register(user) {
    return api.userRegistration({
      username: user.username,
      email: user.email,
      phoneNumber: user.phoneNumber,
      password: user.password,
      referralGear: user.gear,
      referralNumber: user.runnerNumber,
      accountType: user.accountType,
      portfolio: user.portfolio,
    });
  }
}
export default new AuthService();
