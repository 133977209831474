<template>
  <q-layout view="lHh Lpr lFf" class="main-layout">
    <BaseHeader />
    <MobileDrawer v-if="isMobile" />
    <div v-if="!isLoginPage" class="subheader home-page">
      <h3 class="subheader-title">{{ $t("common.subHeaderTitle") }}</h3>
      <div class="subheader-overlay"></div>
    </div>
    <q-page-container>
      <router-view />
    </q-page-container>
    <BaseFooter />
  </q-layout>
</template>

<script>
import BaseHeader from "./layouts/BaseHeader.vue";
import BaseFooter from "./layouts/BaseFooter.vue";
import MobileDrawer from "./layouts/MobileDrawer.vue";
export default {
  name: "LayoutDefault",

  components: {
    BaseHeader,
    BaseFooter,
    MobileDrawer,
  },
  computed: {
    isLoginPage() {
      return this.$route.name === "Login";
    },
    isMobile() {
      return this.$q.screen.width < 1620;
    },
  },
};
</script>
<style scoped lang="scss">
.q-page-container {
  padding-top: 0;
}
.main-layout {
  background: #191919;
}

.background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;

  mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
}
.subheader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("./assets/homePage.jpg");
  background-size: cover;
  background-position: center;
  height: 200px;
  z-index: 48;
  &.user-panel {
    background-image: url("./assets/userpanel.jpg");
  }
  &.home-page {
    background-image: url("./assets/homePage.jpg");
  }
  &.add-photo {
    background-image: url("./assets/addimage.jpg");
  }
  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 48;
  }
  &-title {
    text-transform: uppercase;
    font-size: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    color: white;
    z-index: 49;
    text-align: center;
    @media (max-width: 548px) {
      font-size: 1.5rem;
    }
  }
}
</style>
