export default {
  ERR_NETWORK : 'Przepraszamy, wystąpił problem sieciowy, spróbuj za jakiś czas..',
  ERR_BAD_RESPONSE: 'Nieprawidłowa odpowiedź serwera. Przepraszamy, spróbuj ponownie, jeśli się nie uda, wróć za jakiś czas.',
  login: {
    loginTitle: "Logowanie",
    emailInfo: 'Jeśli chcesz uzyskać prowizję za wrzucone zdjęcia, podaj adres e-mail powiązany z kontem paypal',
    email: 'Email',
    phoneNumberInfo: 'Aby móc wypłacać pieniądze, podaj swój numer telefonu.',
    phoneNumber: 'Numer telefonu',
    password: 'Hasło',
    repassword: 'Powtórz hasło',
    forgotPassword: 'Nie pamiętasz hasła?',
    loginButton: 'Zaloguj się',
    loginNotify: 'Zalogowano!',
    register: 'Rejestracja',
    username: 'Nazwa użytkownika',
    registration: 'Zarejestruj Się',
    createAccount: 'Załóż konto',
    referal: 'Dodaj polecenie',
    referalTitle: 'Dodaj polecającego biegacza',
    referalDesc: '(opcjonalne) Możesz podać numer znajomego biegacza - jeśli wszyscy jego znajomi dodadzą co najmniej 50 zdjęć innych osób, dostanie 5% zniżki!',
    runnerNumber: 'Numer biegacza',
    accountType: 'Typ konta',
    supporter: 'Kibic',
    photographer: 'Fotograf',
    portfolioTitle: 'Jestem profesjonalnym fotografem, chcę otrzymywać wynagrodzenie za swoje zdjęcia. Zgłaszam się do weryfikacji - Link do mojego portfolio:',
    portfolio: 'Portfolio',
  },
  validation: {
    required: 'Pole jest obowiązkowe',
    minValue: 'Minimalna ilość znaków 3',
    email: 'Wpisz poprawny email',
    phoneNumber: 'Wpisz poprawny numer telefonu',
    samePassword: 'Hasła powinne być identyczne',
    completeAllFields: 'Uzupełnij wszystkie dane',
    accountType: 'Typ konta jest nieprawidłowy',
    portfolio: 'Portfolio jest nieprawidłowe',
  },
  duplicates: {
    general: 'Twoje ostatnie zdjęcia, które przesłałeś, są duplikatami zdjęć, które już istnieją w systemie. Następujące biegi zawierają duplikaty:',
    unknownRace: 'Nieznany bieg',
  },
  navigation: {
    home: 'Glówna',
    addPhoto: 'Zrób zdjęcie',
    walletProfile: 'Portfel',
    logout: 'Wyloguj się',
    profile: 'Twój profil',
    signAs: 'Zalogowany jako',
    signIn: 'Załoguj się',
    role: 'Rola',
  },
  role: {
    photographer: 'Fotograf',
    supporter: 'Kibic',
    photographer_verification: 'Fotograf (weryfikacja)',
  },
  addPhoto: {
    title: 'Dodaj zdjęcia',
    blockedText: 'Aby dodać zdjęcia zaloguj się',
    subHeaderTitle: 'RunPixie - Odnajdź siebie!',
    agreement: 'Umieszczając zdjęcia, akceptujesz regulamin serwisu i licencję'
  },
  common: {
    clear: 'Wyczyść',
    add: 'Dodaj',
    subHeaderTitle: 'RunPixie - znajdź siebie!',
    country: 'Kraj',
    completeData: 'Uzupełnij dane',
    city: 'Miasto',
    selectDate: 'Wybierz datę' ,
    close: 'Zamknij',
    submit: 'Zatwierdż',
    selectedDate: 'Wybrana data:',
    gear: 'Bieg',
    summary: 'Podsumowanie',
    imagesCount: 'Wybrano zdjęc',
    date: 'Data',
    next: 'Dalej',
    return: 'Powrót',
    ups: 'Coś poszło nie tak!',
    turnOnThumbs: 'Włącz miniaturki w podglądzie',
    turnOnThumbsInfo: 'Włącz tą opcje jeśli chcesz zobaczyć miniaturki w podglądzie (niezalecane przy dodawaniu większej ilości zdjęć)',
    statuteRoute: '/regulamin-korzystania-z-serwisu/',
    checkHere: 'sprawdź tutaj',
    understand: 'Rozumiem',
  },
  userPanel: {
    title: 'Panel użytkownika',
    hello: 'Cześć',
    addedPhoto: 'Dodane zdjęcia',
    inSystem: 'W systemie od',
    country: 'Kraj',
    city: 'Miasto',
    gear: 'Bieg',
    emailChanges: 'Email został zmieniony',
    badEmail: 'Wpisz poprawny email',
    walletRequestsTitle: 'Zarejestrowane prośby o wyplaty',
    walletCommissionsTitle: 'Zarejestrowane prowizje',
    blockedMoneyInfo: 'Wartość Twoich aktualnych zgłoszeń o wypłatę (w blokadzie)',
    noPersonalDataInfo: 'Aby zgłosić wniosek o wypłatę, uzupełnij swoje dane personalne tj. imię, nazwisko, numer telefonu, adres',
    noWithdrawIntegration: 'Aby zgłosić wniosek o wypłatę, Twoje konto musi być zintegrowane z partnerem płatności.',
    withdrawMoney: 'Wypłać środki',
    minWithdrawValue: 'Minimalna kwota wypłaty',
    withdraw: 'Wypłać',
    wallet: 'Portfel',
    ownCommisionsUnpaid: 'Suma niewypłaconych prowizji',
    ownCommisionsAll: 'Suma wszystkich uzyskanych prowizji',
    albumName: 'Nazwa albumu',
    commission: 'Prowizja',
    status: 'Status',
    sum: 'Kwota',
    yourData: 'Twoje dane',
    phoneNumber: 'tel.',
    paymentInfo: 'Dane niezbędne do realizacji wypłat - jeśli po zmianie, widzisz dalej stare dane, wyloguj się i zaloguj ponownie.',
    paid: 'Wypłacono',
    tableWalletHint: 'Poniżej lista albumów z Twoimi zdjęciami które ktoś kupił. Pamiętaj, że kwota uzyskanej prowizji to (a) możliwa premia za najlepsze zdjęcie w pakiecie oraz (b) przypadająca proporcjonalnie według liczby zdjęć kwota prowizji do podziału pomiędzy autorów.',
    tableColumns: {
      album: 'Nr sprzedanego albumu',
      commission: 'Kwota uzyskanej prowizji',
      gear: 'Bieg',
      date: 'Data',
      commissionData: 'Data wypłaty prowizji',
      images: 'Zdjecia',
      image: 'Zdjecie',
      name: 'Nazwa'
    },
    tableTitle: 'Lista sprzedanych albumów',
    imagesTableTitle: 'Lista Twoich zdjęcia w ramach albumu',
    promptWalletInfo: 'Wypłacasz prowizję za udzieloną RunPixie licencję na zdjęcia, które zostały wykupione przez biegaczy, zgodnie z',
    promptWalletInfo2: 'Wypłata zalecana powyżej <strong>20 zł</strong>, wymagana po przekroczeniu <strong>500 zł</strong>. Koszt wypłaty <strong>2,5 zł</strong>. Środki niewypłacone w ciągu 2 lat przepadają',
    regulamin: 'regulaminem serwisu i umową licencyjną',
    userData: {
      name: 'Imie',
      surname: 'Nazwisko',
      nrTelephone: 'Telefon',
      adress: 'Adres',
      role: 'Rola',
    },
    empty: 'Nie uzupełnione',
    completeData: 'Uzupełnij dane (zostaniesz przekierowany do nowej karty [niezbędne będzie ponowne zalogowanie])',
    editData: 'Edytuj dane',
    solded: 'Sprzedano',
    withdrawNotConnectedInfo: 'Brak połączenia z zewnętrzną platformą płatniczą',
    connectHereLabel: 'Połącz tutaj'
  },
  status: {
    waiting: 'Oczekujące',
    rejected: 'Odrzucone',
    completed: 'Zrealizowane',
  },
  error : {
    badAuth : "Błąd autoryzacji.",
    badAuthToken : "Błąd autoryzacji - brak tokenu.",
    badAuthPriviliges : "Błąd autoryzacji - potrzebujesz wyższych uprawnień.",
    badAuthTokenProblem : "Błąd autoryzacji - problem z tokenem.",
    route : "Nie możesz korzystać z tej endpointu.",
    walletNoAmmount : "Brak pól kwoty, autora lub balansu konta.",
    code : "Coś poszło nie tak z kodem.",
    imageFail : "Houston, mamy problem z funkcją przesyłania obrazu.",
    usernameRequired : 'Pole nazwy użytkownika jest wymagane.',
    emailRequired : 'Pole e-mail jest wymagane.',
    phoneNumber: 'Pole numer telefonu jest wymagane',
    phoneNumberExists: 'Numer telefonu jest już w użyciu.',
    passwordRequired : 'Pole hasła jest wymagane.',
    invalidCredentials : 'Nieprawidłowa nazwa użytkownika lub hasło.',
    emailExists : 'E-mail już istnieje, spróbuj zresetować hasło.',
    addedImagesInfo: 'dodanych zdjęć:',
    renewSendImages: 'Wznów wysyłanie zdjęć',
    sendLater: 'Zamknij, wyślę później',
    sendLaterInfo: 'Zdjęcia z kroku pierwszego zostały zaktualizowane, pozostały tylko te które nie zostały wgrane',
    catchedError: 'Wystąpił błąd',
    registerWithdrawError: 'Wystąpił błąd w rejestracji z partnerem płatności',
  },
  success: {
    registeredWithReq : "Prośba o wypłatę została zarejestrowana, dziękujemy.",
    imageAdded : "Wszystko dobrze. Zdjęcia dodane, dziękujemy!",
    accountHasBeenCreated : "Konto zostało założone poprawnie!",
    accountHasBeenCreatedPhotographerVerify: "Konto zostało założone poprawnie! Typ Twojego konta jest weryfikowany.",
  }
};
