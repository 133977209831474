import { createStore } from "vuex";
import { auth } from "./auth.module";
import { global } from "./global.module";
const store = createStore({
  modules: {
    auth,
    global
  },
});
export default store;
