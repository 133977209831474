<template>
  <q-header class="bg-transparent text-white" reveal>
    <q-toolbar>
      <q-btn
        v-if="isMobile"
        flat
        dense
        round
        @click="setDrawer"
        aria-label="Menu"
        icon="menu"
      />

      <router-link to="/">
        <img class="logo" :src="require('../assets/runpixie_logo.webp')" />
      </router-link>

      <q-space />
      <q-tabs v-if="!isMobile && navigationOptions.length" v-model="tab">
        <q-tab
          v-for="(option, index) in navbarOptions"
          :key="`${index}_${option.id}`"
          :name="option.key"
          :label="option.link !== '/' ? option.name : $t(`navigation.addPhoto`)"
          @click="handleClick(option)"
        />
      </q-tabs>
      <q-btn v-if="loggedIn" dense flat no-wrap class="q-ml-md">
        <q-avatar rounded size="28px">
          <img :src="require('@/assets/userDefault.png')" />
        </q-avatar>
        <q-icon name="arrow_drop_down" size="28px" />

        <q-menu auto-close>
          <q-list dense>
            <q-item class="GL__menu-link-signed-in">
              <q-item-section>
                <div>
                  {{ $t("navigation.signAs") }} <strong>{{ userName }}</strong>
                </div>
                <div>
                  {{ $t("navigation.role") }} <strong>{{ $t(`role.${userRole}`) }}</strong>
                </div>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable class="GL__menu-link" @click="redirectUserPanel">
              <q-item-section>{{ $t("navigation.profile") }}</q-item-section>
            </q-item>
            <q-item clickable class="GL__menu-link" @click="handleLogout">
              <q-item-section>{{ $t("navigation.logout") }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-btn
        v-if="!isLoginPage && !loggedIn"
        color="primary"
        class="q-ml-md"
        icon="login"
        round
        @click="goToLogin"
      />
      <q-icon size="32px" color="white" name="language" class="language-button q-px-md">
        <q-menu
          v-if="langsList.length"
          fit
          transition-show="flip-right"
          transition-hide="flip-left"
        >
          <q-list>
            <q-item
              v-for="(lang, index) in langsList"
              :key="index"
              clickable
              :class="[{ active: setActiveLang === lang.name }, 'lang-item']"
              @click="setLanguage(lang.name)"
            >
              <q-item-section>
                <q-avatar size="32px">
                  <img :src="require(`../assets/${lang.icon}`)" />
                </q-avatar>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-icon>
    </q-toolbar>
  </q-header>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "BaseHeader",
  data() {
    return {
      activeOption: null,
      langsList: [
        { id: 1, name: "pl", icon: "pl.png" },
        { id: 2, name: "en", icon: "en.png" },
        { id: 3, name: "sk", icon: "sk.png" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      loggedIn: (state) => state.auth.status.loggedIn,
      isMobileDrawerShown: (state) => state.global.isMobileDrawerShown,
      navigationOptions: (state) => state.global.navigationOptions,
      lang: (state) => state.global.lang,
    }),
    setActiveLang() {
      return this.$root.$i18n.locale ? this.$root.$i18n.locale : "pl";
    },
    isLoginPage() {
      return this.$route.name === "Login";
    },
    navbarOptions() {
      const walletId = 100;
      return this.navigationOptions.filter((el) => el.id !== walletId);
    },
    setBgSubheader() {
      return this.activeOption?.bgClass;
    },
    filteredHeaderOptions() {
      return this.headerOptions.filter((el) => !el.hidden);
    },
    isMobile() {
      return this.$q.screen.width < 1620;
    },
    userName() {
      const { user_display_name } = this.user;
      return user_display_name;
    },
    userRole() {
      const { role } = this.user;
      return role;
    },  
    getRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.getNavbarData(this.lang);
  },
  methods: {
    ...mapActions({
      getNavbarData: "global/getNavbarData",
      setPageLang: "global/setPageLang",
    }),
    setLanguage(language) {
      this.getNavbarData(language);
      this.setPageLang({ language: language, i18n: this.$root.$i18n });
    },
    handleClick(val) {
      if (val.id === 99) {
        this.$router.push(val.link);
      } else {
        const { url } = val;
        window.open(url, "_blank");
      }
    },
    setDrawer() {
      this.$store.dispatch("global/setDrawerState", !this.isMobileDrawerShown);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    goToLogin() {
      this.$router.push("/login");
    },
    redirectUserPanel() {
      this.$router.push("/userPanel");
    },
  },
};
</script>

<style lang="scss">
.lang-item {
  transition: all 0.3s ease-in-out;
}
.active {
  background: rgba($primary, 0.25);
}
.logo {
  max-height: 44px;
}
.q-toolbar {
  z-index: 50;
}
</style>
