<template>
  <q-spinner class="information-form__spinner" v-if="loading" />
  <div v-else>
    <q-select
      clearable
      filled
      v-model="selectedCountry"
      :options="filteredCountries"
      :label="$t('common.country')"
      class="q-mb-sm"
    />
    <q-select
      clearable
      filled
      v-model="selectedCity"
      :options="filterCities"
      :label="$t('common.city')"
      class="q-mb-sm"
    />
    <q-btn
      icon="event"
      color="primary"
      :label="$t('common.selectDate')"
      class="full-width q-mb-sm"
    >
      <q-popup-proxy
        @before-show="updateProxy"
        transition-show="scale"
        transition-hide="scale"
      >
        <q-date v-model="proxyDate">
          <div class="row items-center justify-end q-gutter-sm">
            <q-btn
              :label="$t('common.close')"
              color="primary"
              flat
              v-close-popup
            />
            <q-btn
              :label="$t('common.submit')"
              color="primary"
              flat
              @click="save"
              v-close-popup
            />
          </div>
        </q-date>
      </q-popup-proxy>
    </q-btn>
    <p v-if="date" class="date-block">
      <q-chip> {{ `${$t("common.selectedDate")} ${date}` }}</q-chip>
    </p>
    <q-select
      clearable
      filled
      v-model="selectedGear"
      :options="filteredGears"
      :label="$t('common.gear')"
      class="q-mb-sm"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      selectedCountry: "",
      selectedCity: "",
      selectedGear: "",
      cities: [],
      gears: [],
      countries: [],
      proxyDate: "",
      date: "",
      loading: true,
      dataLoaded: 0,
    };
  },
  computed: {
    ...mapState({
      citiesList: (state) => state.global.citiesList,
      countriesList: (state) => state.global.countriesList,
      gearsList: (state) => state.global.gearsList,
    }),
    filterCities() {
      let copyCities = [...this.cities];
      let citiesIds = [];
      if (this.selectedCountry) {
        this.selectedCountry?.acf?.assign_cities.forEach((city) => {
          citiesIds.push(city.ID);
        });
        copyCities = copyCities.filter((el) => _.includes(citiesIds, el.id));
      }
      return copyCities;
    },
    filteredCountries() {
      const copyCountries = [...this.countries];
      return copyCountries;
    },
    filteredGears() {
      let copyGears = [...this.gears];
      let gearsIds = [];
      if (this.selectedCity) {
        const isGears =
          this.selectedCity.acf && this.selectedCity.acf.assign_gear;
        if (isGears)
          this.selectedCity?.acf?.assign_gear.forEach((gear) => {
            gearsIds.push(gear.ID);
          });
        copyGears = copyGears.filter((el) => _.includes(gearsIds, el.id));
      }
      return copyGears;
    },
  },
  watch: {
    dataLoaded(val) {
      if (val === 3) {
        this.loading = false;
      }
    },
    selectedCountry(val) {
      this.$emit("setCountry", val);
    },
    selectedCity(val) {
      if (val) {
        let countryId = val?.acf?.assign_country;
        if (countryId) {
          this.selectedCountry = this.countries.filter(
            (country) => country.id === countryId
          )[0];
        }
      }
      this.$emit("setCity", val);
    },
    selectedGear(val) {
      if (val) {
        this.date = moment(val.gear_date).format("yyyy/MM/DD");
        this.updateProxy();
        let cityId = val?.custom?.assign_city;
        if (cityId) {
          this.selectedCity = this.cities.filter(
            (citi) => citi.id == cityId
          )[0];
        }
      }
      this.$emit("setGear", val);
    },
  },
  methods: {
    updateProxy() {
      this.proxyDate = this.date;
      this.$emit("setDate", this.date);
    },
    save() {
      this.date = this.proxyDate;
      this.$emit("setDate", this.date);
    },
  },
  mounted() {
    let formData = null;
    if (sessionStorage.getItem("formData") !== null) {
      formData = JSON.parse(sessionStorage?.getItem("formData")).formData;
      if (formData) {
        this.date = formData.date;
      }
    }
    this.cities = this.citiesList;
    this.dataLoaded++;
    if (formData) {
      this.selectedCity = this.cities.filter(
        (el) => el.id === formData?.city
      )[0];
    }
    this.countries = this.countriesList;
    this.dataLoaded++;
    if (formData) {
      this.selectedCountry = this.countries.filter(
        (el) => el.id === formData?.country
      )[0];
    }
    this.gears = this.gearsList;
    setTimeout(() => {
      this.dataLoaded++;
    }, 300);

    if (formData) {
      this.selectedGear = this.gears.filter(
        (el) => el.id === formData?.gear
      )[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.information-form {
  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
