<template>
  <div class="q-pa-md">
    <q-dialog v-model="isPrompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">{{ $t("userPanel.withdrawMoney") }}</div>
          <div class="text-caption" v-html="$t('userPanel.promptWalletInfo2')"></div>
          <div class="text-h6 q-mt-md text-center">
              <span>{{ `${$t("userPanel.ownCommisionsUnpaid")}: ${walletMoney}zł` }}</span>
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="text-label">
            {{ $t("userPanel.promptWalletInfo") }}
            <a
              href="https://runpixie.com/regulamin-korzystania-z-serwisu/"
              target="_blank"
            >
              {{ $t("userPanel.regulamin") }}.</a
            >
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat :label="$t('common.close')" v-close-popup />
          <q-btn
            flat
            :label="$t('userPanel.withdraw')"
            :disable="!walletMoney || walletMoney < 20"
            @click="withdraw"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-expansion-item
      dark
      padding
      bordered
      :disable="!registeredCommissions.length && !withdrawRequestsList.length"
      class="rounded-borders expansion-block"
      v-model="expanded"
    >
      <template v-slot:header>
        <div class="expansion-block__header">
          <div class="expansion-block__avatar">
            <q-item-section avatar>
              <q-avatar icon="wallet" dense />
            </q-item-section>
            <div class="expand-label">
              <span>{{ $t("userPanel.wallet") }}</span>
              <span>{{ `${$t("userPanel.ownCommisionsUnpaid")}: ${walletMoney}zł` }}</span>
              <span>{{ `${$t("userPanel.ownCommisionsAll")}: ${walletSumOfAllTime}zł` }}</span>
            </div>
          </div>
          <div class="expansion-block__actions">
            <q-btn
              color="primary"
              class="q-mx-md q-px-lg"
              dense
              :label="$t('userPanel.withdraw')"
              @click.prevent.stop="withdrawPrompt"
            />
          </div>
        </div>
      </template>
      <q-card v-if="registeredCommissions.length || withdrawRequestsList.length">
        <div class="text-subtitle2 q-px-md q-pt-md">
          {{ $t("userPanel.tableWalletHint") }}
        </div>
        <div>
          <WalletAlbumsTable
            v-if="registeredCommissions && registeredCommissions.length"
            :albums="registeredCommissions"
            @showImageModal="showImageModal"
          />
          <q-dialog v-model="imagesModal">
            <ImagesAlbumTable :images="selecetedImages" :gear="selectedAlbum" />
          </q-dialog>
        </div>
        <q-separator />
        <q-card-section>
          <q-list
            v-if="withdrawRequestsList && withdrawRequestsList.length"
            bordered
            separator
            style="max-height: 400px; overflow: auto"
          >
            <q-item-label header>{{ $t("userPanel.walletRequestsTitle") }}</q-item-label>
            <q-item
              v-for="(requests, index) in withdrawRequestsList"
              :key="`${index}-requests`"
              v-ripple
              clickable
            >
              <q-item-section>
                <div class="information__section">
                  <span class="text-primary">{{
                    `${$t("userPanel.sum")}: ${requests.withdrawal_amount}zł`
                  }}</span>
                  <span
                    v-if="requests.custom && requests.custom.withdrawal_status"
                    class="q-mb-sm"
                  >
                    {{ $t("userPanel.status") }}:
                    {{ $t(`status.${requests.custom.withdrawal_status}`) }}
                  </span>
                  <span>
                    <strong>{{ `${formateDate(requests.post_date)}` }}</strong></span
                  >
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api/api.js";
import WalletAlbumsTable from "./WalletAlbumsTable.vue";
import ImagesAlbumTable from "./ImagesAlbumTable.vue";
import {mapActions, mapState} from "vuex";
export default {
  name: "WalletComponent",
  components: {
    WalletAlbumsTable,
    ImagesAlbumTable,
  },
  props: {
    walletMoney: {
      require: false,
      default: 0,
      type: Number,
    },
    walletSumOfAllTime: {
      require: false,
      default: 0,
      type: Number,
    },
    registeredCommissions: {
      require: false,
      default: null,
      type: Array,
    },
    withdrawRequestsList: {
      require: false,
      default: null,
      type: Array,
    },
    withdrawalRequestsSumAmmount: {
      require: false,
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      expanded: false,
      moneyWithdraw: 0,
      isPrompt: false,
      inputError: "",
      imagesModal: false,
      selecetedImages: [],
      selectedAlbum: "",
    };
  },
  created() {
    this.expandWallet();
  },
  watch:{
    expanded(value) {
      this.$router.push({ query: { wallet: Number(value) } })
    }
  } ,
  computed: {
    availableMoney() {
      const moneyToWithdraw = this.walletMoney - this.withdrawalRequestsSumAmmount;
      return moneyToWithdraw;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
    hasRouteWalletParam() {
      return this.$route.query?.wallet;
    },

  },
  methods: {
    ...mapActions({
      getUserWalletData: "global/getUserWalletData",
      getWithdrawRequestList: "global/getWithdrawRequestList",
    }),
    expandWallet() {
      this.expanded = this.hasRouteWalletParam === "1" ;
    },
    showImageModal(data) {
      this.imagesModal = true;
      this.selectedAlbum = data.album_name;
      this.selecetedImages = data.images;
    },
    formateDate(date) {
      return moment(date).format("YYYY-DD-MM");
    },
    withdrawPrompt() {
      this.isPrompt = true;
    },
    async withdraw() {
      if (
        !this.availableMoney ||
        this.availableMoney < 20
      ) {
        this.inputError = `${this.$t("userPanel.blockedMoneyInfo")} ${
          this.withdrawalRequestsSumAmmount
        } zł`;
        this.$q.notify({
          icon: "error",
          color: "negative",
          message: this.inputError,
          timeout: 0,
          actions: [
            { icon: 'close', color: 'white' }
          ]
        });
        return;
      }

      if (
        !this.user.phone_number ||
        !this.user.postcode ||
        !this.user.adress ||
        !this.user.city ||
        !this.user.billing_first_name ||
        !this.user.billing_last_name
      ) {
        this.inputError = this.$t("userPanel.noPersonalDataInfo");
        this.$q.notify({
          icon: "error",
          color: "negative",
          message: this.inputError,
          timeout: 0,
          actions: [
            { icon: 'close', color: 'white' }
          ]
        });
        return;
      }
      
      if (
        !this.user.withdraw_user_id ||
        this.user.withdraw_user_id == ''
      ) {
        this.inputError = this.$t("userPanel.noWithdrawIntegration");
        this.$q.notify({
          icon: "error",
          color: "negative",
          message: this.inputError,
          timeout: 0,
          actions: [
            { icon: 'close', color: 'white' }
          ]
        });
        return;
      }

      this.inputError = "";
      if (this.availableMoney >= 20) {
        try {
          const response = await api.withdrawUserPayment(
            this.availableMoney,
            this.walletMoney
          );

          await this.getUserWalletData();
          await this.getWithdrawRequestList();

          const resMessage = response.data.message;
          const isError = response.data.error;

          this.$q.notify({
            icon: !isError ? "done" : "error",
            color: !isError ? "positive" : "negative",
            message: this.$t(resMessage),
            timeout: 0,
            actions: [
              { icon: 'close', color: 'white' }
            ]
          });
        } catch (e) {
          this.$q.notify({
            icon: "error",
            color: "negative",
            message: this.$t("common.ups"),
            timeout: 0,
            actions: [
              { icon: 'close', color: 'white' }
            ]
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expansion-block {
  &__header {
    width: 100%;
    display: flex;
  }
  &__avatar {
    display: flex;
  }
  &__actions {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    justify-content: center;
  }
}
.expand-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.information {
  &__section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
