export default {
    ERR_NETWORK : 'Ospravedlňujeme sa, vyskytol sa sieťový problém, skúste to neskôr..',
    ERR_BAD_RESPONSE: 'Nesprávna odpoveď servera. Ospravedlňujeme sa, skúste to znova, ak sa to nepodarí, vráťte sa neskôr.',
    login: {
      loginTitle: "Prihlásenie",
      emailInfo: 'Ak chcete získať províziu za nahrané fotografie, zadajte e-mailovú adresu spojenú s účtom paypal',
      email: 'Email',
      phoneNumberInfo: 'Ak chcete vyberať peniaze, zadajte svoje telefónne číslo.',
      phoneNumber: 'Telefónne číslo',
      password: 'Heslo',
      repassword: 'Zopakujte heslo',
      forgotPassword: 'Zabudli ste heslo?',
      loginButton: 'Prihlásiť sa',
      loginNotify: 'Prihlásený!',
      register: 'Registrácia',
      username: 'Užívateľské meno',
      registration: 'Zaregistrujte sa',
      createAccount: 'Vytvorte účet',
      referal: 'Pridajte odporúčanie',
      referalTitle: 'Pridajte odporúčajúceho bežca',
      referalDesc: '(voliteľné) Môžete uviesť číslo bežca priateľa - ak všetci jeho priatelia pridajú aspoň 50 fotografií iných osôb, dostane 30% zľavu!',
      runnerNumber: 'Číslo bežca'
    },
    validation: {
      required: 'Pole je povinné',
      minValue: 'Minimálny počet znakov 3',
      email: 'Zadajte platný email',
      phoneNumber: 'Zadajte platné telefónne číslo',
      samePassword: 'Heslá by mali byť rovnaké',
      completeAllFields: 'Vyplňte všetky údaje'
    },
    navigation: {
      home: 'Hlavná',
      addPhoto: 'Urobte fotografiu',
      logout: 'Odhlásiť sa',
      profile: 'Váš profil',
      signAs: 'Prihlásený ako',
      signIn: 'Prihlásiť sa',
    },
    addPhoto: {
      title: 'Pridajte fotografie',
      blockedText: 'Ak chcete pridať fotografie, prihláste sa',
      subHeaderTitle: 'RunPixie - Nájdite seba!',
      agreement: 'Umiestnením fotografií akceptujete pravidlá služby a licenciu'
    },
    common: {
      clear: 'Vymazať',
      add: 'Pridať',
      subHeaderTitle: 'RunPixie - nájdite seba!',
      country: 'Krajina',
      completeData: 'Vyplňte údaje',
      city: 'Mesto',
      selectDate: 'Vyberte dátum',
      close: 'Zavrieť',
      submit: 'Potvrdiť',
      selectedDate: 'Vybraný dátum:',
      gear: 'Beh',
      summary: 'Zhrnutie',
      imagesCount: 'Vybrané fotografie',
      date: 'Dátum',
      next: 'Ďalej',
      return: 'Návrat',
      ups: 'Niečo sa pokazilo!',
      turnOnThumbs: 'Zapnite náhľady v náhľade',
      turnOnThumbsInfo: 'Zapnite túto možnosť, ak chcete vidieť náhľady v náhľade (neodporúča sa pri pridávaní väčšieho množstva fotografií)',
      statuteRoute: '/pravidla-pouzivania-sluzby/',
      checkHere: 'skontrolujte tu'
    },
    userPanel: {
      title: 'Užívateľský panel',
      hello: 'Ahoj',
      addedPhoto: 'Pridané fotografie',
      inSystem: 'V systéme od',
      country: 'Krajina',
      city: 'Mesto',
      gear: 'Beh',
      emailChanges: 'Email bol zmenený',
      badEmail: 'Zadajte platný email',
      walletRequestsTitle: 'Registrované žiadosti o výber',
      walletCommissionsTitle: 'Registrované provízie',
      blockedMoneyInfo: 'Hodnota vašich aktuálnych žiadostí o výber (v blokáde)',
      noPersonalDataInfo: 'Ak chcete podať žiadosť o výber, vyplňte svoje osobné údaje, tj. meno, priezvisko, telefónne číslo, adresa',
      noBillonIntegration: 'Ak chcete podať žiadosť o výber, váš účet musí byť integrovaný s platobným partnerom Billon',
      withdrawMoney: 'Vybrať prostriedky',
      minWithdrawValue: 'Minimálna suma výberu',
      withdraw: 'Vybrať',
      wallet: 'Peňaženka',
      ownCommisionsUnpaid: 'Súčet nevyplatených provízií',
      ownCommisionsAll: 'Súčet všetkých získaných provízií',
      albumName: 'Názov albumu',
      commission: 'Provízia',
      status: 'Stav',
      sum: 'Suma',
      yourData: 'Vaše údaje',
      phoneNumber: 'tel.',
      paymentInfo: 'Údaje potrebné na realizáciu výplat - ak po zmene stále vidíte staré údaje, odhláste sa a znova sa prihláste.',
      paid: 'Vyplatené',
      tableWalletHint: 'Nižšie je zoznam albumov s vašimi fotografiami, ktoré niekto kúpil. Pamätajte, že suma získanej provízie je (a) možná prémia za najlepšiu fotografiu v balíku a (b) proporčná suma provízie na rozdelenie medzi autorov podľa počtu fotografií.',
      tableColumns: {
        album: 'Číslo predaného albumu',
        commission: 'Suma získanej provízie',
        gear: 'Beh',
        date: 'Dátum',
        commissionData: 'Dátum výplaty provízie',
        images: 'Fotografie',
        image: 'Fotografia',
        name: 'Názov'
      },
      tableTitle: 'Zoznam predaných albumov',
      imagesTableTitle: 'Zoznam vašich fotografií v rámci albumu',
      promptWalletInfo: 'Vyberáte províziu za udelenú licenciu RunPixie na fotografie, ktoré boli zakúpené bežcami, v súlade s',
      promptWalletInfo2: 'Výber sa odporúča nad <strong>20 €</strong>, je požadovaný po prekročení <strong>500 €</strong>. Náklady na výber <strong>2,5 €</strong>. Prostriedky nevyplatené do 2 rokov prepadajú',
      regulamin: 'pravidlami služby a licenčnou zmluvou',
      userData: {
        name: 'Meno',
        surname: 'Priezvisko',
        nrTelephone: 'Telefón',
        adress: 'Adresa'
      },
      empty: 'Nevyplnené',
      completeData: 'Vyplňte údaje (budete presmerovaný na novú kartu [bude potrebné sa znova prihlásiť])',
      editData: 'Upraviť údaje',
      solded: 'Predané',
      billonNotConnectedInfo: 'Žiadne spojenie s účtom Billon Group (potrebné pre výbery)',
      connectHereLabel: 'Pripojte tu'
    },
    status: {
      waiting: 'Čakajúce',
      rejected: 'Odmietnuté',
      completed: 'Dokončené',
    },
    error : {
      badAuth : "Chyba autentifikácie.",
      badAuthToken : "Chyba autentifikácie - chýba token.",
      badAuthPriviliges : "Chyba autentifikácie - potrebujete vyššie oprávnenia.",
      badAuthTokenProblem : "Chyba autentifikácie - problém s tokenom.",
      route : "Nemôžete používať tento koncový bod.",
      walletNoAmmount : "Chýbajú polia sumy, autora alebo zostatku na účte.",
      code : "Niečo sa pokazilo s kódom.",
      imageFail : "Houston, máme problém s funkciou odosielania obrázkov.",
      usernameRequired : 'Pole užívateľského mena je povinné.',
      emailRequired : 'Pole e-mailu je povinné.',
      phoneNumber: 'Pole telefónneho čísla je povinné',
      phoneNumberExists: 'Telefónne číslo sa už používa.',
      passwordRequired : 'Pole hesla je povinné.',
      invalidCredentials : 'Nesprávne užívateľské meno alebo heslo.',
      emailExists : 'E-mail už existuje, skúste obnoviť heslo.',
      addedImagesInfo: 'pridaných fotografií:',
      renewSendImages: 'Obnovte odosielanie fotografií',
      sendLater: 'Zavrieť, pošlem neskôr',
      sendLaterInfo: 'Fotografie z prvého kroku boli aktualizované, zostali len tie, ktoré neboli nahrané',
      catchedError: 'Vyskytla sa chyba',
      registerBillonError: 'Vyskytla sa chyba pri registrácii Billon',
    },
    success: {
      registeredWithReq : "Žiadosť o výber bola zaregistrovaná, ďakujeme.",
      imageAdded : "Všetko v poriadku. Fotografie pridané, ďakujeme!",
      accountHasBeenCreated : "Účet bol úspešne vytvorený!",
    }
  };
  