import { createWebHistory, createRouter } from "vue-router";
import LoginPage from "@/pages/login/LoginPage.vue";
import AddPhotoPage from "@/pages/addPhoto/AddPhotoPage.vue";
import UserPanelPage from "@/pages/userPanel/UserPanelPage.vue";

const routes = [
  {
    path: "/",
    name: "addPhoto",
    component: AddPhotoPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/userPanel",
    name: "userPanel",
    component: UserPanelPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
export default router;
